import * as TransactionConstant from '../../TransactionConstants';
import AccountSelection from '../../../../common/AccountSelection';
import ConfirmationComponent from '../../Common/ConfirmationComponent';
import { connect } from 'react-redux';
import { ConfirmationMessage } from 'modules/CustomerManagementModule/components';
import EConsent from '../../Common/EConsent/EConsentComponent';
import EConsentData from '../../../../common/EConsent/EConsentData';
import ErrorBoundary from '../../Common/ErrorBoundary/errorboundary';
import {
    exchangeActions,
    accOpeningActions,
    fundAccountActions,
    masterRegistrationAccountActions,
    tradingWindowActions,
    liquidationActions,
    transactionHistoryActions,
} from '../../../../shared/Actions';
import ExchangeReview from '../ReviewConformComponent/ExchangeReview';
import FundSelection from '../../Liquidation/FundSelection';
import FundSummary from '../../Common/FundSummary';
import { getToken } from 'utils';
import InvestmentSelection from '../InvestmentSelection/InvestmentSelectionComponent';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import styles from './ExchangeMainStyles';
import WBlueBanner from '../../../../common/WBlueBanner/WBlueBanner';
import { WBreadCrumb, PageHeading, ConfirmModal,ConformationModal } from '../../../../common';
import WStepper from '../../../../common/WStepper/WStepper';
import WStepperMobile from '../../../../common/WStepper/WStepperMobile';
import Authenticate from 'common/Authenticate/Authenticate';
import { isTokenAvailable } from 'common/Authenticate/session';
import { getValidValue, getValidValueOf } from 'commonHelper/CommonHelperFunctions';
import { getDataFromORCondition } from 'utils/utils';
import { removeComma } from 'common/Formatter/CurrencyFormatter';


const stepperStyle = { width: 1107, height: 60, paddingRight: 90, paddingLeft: 150, marginBottom: 30 };

const Stepper = styled(WStepper)`
    margin-bottom: 40px;
    margin-top: 44px;
`;

class ExchangeMainComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageIndex: 0,
            childrenState: [],
            displayNotification: false,
            notificationMsg: {},
            setMasterRegAccount: false,
            showCancelExchangeModal: false,
            cancelExchange: false,
            addressValid: true,
            showMasterRegForeignAddressModal: false,
            showCostBasisWarningMessage: false,
            selectedFundCostBasis: '',
            showAuthBox: false,
            showConfirmModal: false
        };
        this.editSection = null;
        this.bottomNav = React.createRef();

        const { getTradingClosureWindow, tradingWindowData } = this.props;
        if (!tradingWindowData.fromDashboard) {
            getTradingClosureWindow({ token: getToken() });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { masterRegistrationAccountData, location, exchangeData } = nextProps;

        let setMasterRegAccount = false;

        if (
            location.state &&
            location.state.pageIndex !== prevState.pageIndex &&
            masterRegistrationAccountData.masterRegAccountList.length > 0
        ) {
            setMasterRegAccount = true;
        }
        return {
            ...prevState,
            setMasterRegAccount,
            showCancelExchangeModal: exchangeData.showCancelExchangeModal
        };
    }

    componentDidMount() {
        const { setMasterRegAccount } = this.state;
        const { location, masterRegistrationAccountData } = this.props;
        sessionStorage.setItem('isRefresh', true);
        if (setMasterRegAccount && masterRegistrationAccountData.masterRegAccountList && location.state.masterId) {
            const accountData = this.accountClickHandler(
                masterRegistrationAccountData.masterRegAccountList,
                location.state.masterId,
            );
            this.onSwitchComponent(true, accountData, 0)();
        }
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    componentWillUnmount() {
        const { clearFundAccountList, clearExchangeData, clearMasterRegInfo, clearLiquidationData } = this.props;
        sessionStorage.setItem('isRefresh', false);
        clearFundAccountList();
        clearMasterRegInfo();
        clearExchangeData();
        clearLiquidationData();
    }

    // This Function will mimic the click from View Positions
    accountClickHandler = (data, masterId) => {
        const accountSelectedType = {};
        const displayData = [];
        let accountSelected = null;

        const allAccounts = data.map(item => {
            const accType = { ...item };
            const { accounts } = accType;
            accType.accounts = accounts.map(eachacc => {
                const acc = { ...eachacc };
                if (Number(masterId) == Number(eachacc.accountNumber)) {
                    acc.checked = !acc.checked;
                } else {
                    acc.checked = false;
                }
                if (acc.checked) {
                    accountSelected = acc;
                    const accountNameDisplay =
                        !acc.accountName || acc.accountName.trim() == ''
                            ? `${!acc.accountType ? '' : acc.accountType} Account ${acc.accountNumber}`
                            : acc.accountName;
                    displayData.push(
                        {
                            field: TransactionConstant.ACCOUNT_NAME_TEXT,
                            value: accountNameDisplay,
                        },
                        {
                            field: TransactionConstant.ACCOUNT_NUMBER_TEXT,
                            value: acc.accountNumber,
                        },
                        {
                            field: TransactionConstant.ACCOUNT_TYPE_TEXT,
                            value: acc.accountType,
                        },
                    );
                    accountSelectedType.holdingGroup = eachacc.holdingGroupName;
                }
                return acc;
            });
            return accType;
        });

        return {
            accountsAvailable: allAccounts,
            accountSelected,
            displayDetails: {
                accountSelection: {
                    data: displayData,
                },
            },
            accountSelectedType,
        };
    };

    getTradingInformation = dateString => {
        const dateVal = new Date(dateString);
        let month = '';
        let year = '';
        let day = '';
        let hour = '';
        let minutes = '';
        if (dateString) {
            month = dateVal.getUTCMonth();
            day = dateVal.getUTCDate();
            year = dateVal.getUTCFullYear();
            hour = dateVal.getUTCHours();
            minutes = dateVal.getUTCMinutes();
        }

        return {
            month,
            year,
            day,
            hour,
            minutes,
        };
    };

    setTradingWarning = () => {
        const { tradingWindowData, setTradingWindowWarning } = this.props;
        const currentLocalTimeStr = new Date().toUTCString();
        const currentTime = Date.parse(currentLocalTimeStr);
        const {
            warningSpan,
            marketEndTime,
            marketStartTime,
            canTradeToday,
            tradingStartTimeEST,
            tradingEndTimeEST,
            tradingActualEndTimeEST,
            tradingDayHour,
            tradingEndTime,
        } = tradingWindowData;
        let { nextBusinessDay } = tradingWindowData;
        const warningDiff = marketEndTime - currentTime;
        const marketOpenDiff = marketStartTime - currentTime;
        const marketClosureDiff = marketEndTime - currentTime;
        let warningMessage = '';

        const effectiveDate = canTradeToday ? tradingEndTime : nextBusinessDay;

        const { day: currentLocalDay } = this.getTradingInformation(currentLocalTimeStr);
        const { day: marketEndDay } = this.getTradingInformation(tradingEndTime);
        if (canTradeToday && marketEndTime !== '') {
            if (currentLocalDay !== marketEndDay) {
                // if the user is in a different time zone and have the local date as next day
                if (canTradeToday && tradingDayHour) {
                    // During market hours
                    const { month, day, year } = this.getTradingInformation(new Date(marketStartTime).toUTCString());
                    nextBusinessDay = `${month + 1}/${day}/${year}`;
                    if (marketClosureDiff < warningSpan) {
                        // 30 mins warning message
                        warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at ${tradingActualEndTimeEST}. You must place your order prior to ${tradingEndTimeEST} to be sure your order gets today’s Net Asset Value.`;
                    }
                } else if (marketOpenDiff > 0 && !tradingDayHour) {
                    // Before market hours
                    const { month, day, year } = this.getTradingInformation(new Date(marketStartTime).toUTCString());
                    nextBusinessDay = `${month + 1}/${day}/${year}`;
                    warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open from ${tradingStartTimeEST} until the market closes at ${tradingActualEndTimeEST}. You are placing an order during market close and will receive the Net Asset Value of today.`;
                } else {
                    // After market hours
                    warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at ${tradingActualEndTimeEST}. You are placing an order after market close and will receive the Net Asset Value of next business day ${nextBusinessDay}`;
                }
                setTradingWindowWarning(false, warningMessage, nextBusinessDay);
            } else if (marketOpenDiff > 0) {
                warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open from ${tradingStartTimeEST} until the market closes at ${tradingActualEndTimeEST}. You are placing an order during market close and will receive the Net Asset Value of today.`;
                setTradingWindowWarning(false, warningMessage, effectiveDate);
            } else if (marketClosureDiff < 0) {
                warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at ${tradingActualEndTimeEST}. You are placing an order after market close and will receive the Net Asset Value of next business day ${nextBusinessDay}`;
                setTradingWindowWarning(false, warningMessage, nextBusinessDay);
            } else if (marketOpenDiff < 0 && marketClosureDiff > 0) {
                if (warningDiff < warningSpan) {
                    warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at ${tradingActualEndTimeEST}. You must place your order prior to ${tradingEndTimeEST} to be sure your order gets today’s Net Asset Value.`;
                    setTradingWindowWarning(false, warningMessage, effectiveDate);
                } else {
                    setTradingWindowWarning(true, '', effectiveDate);
                }
            }
        } else {
            warningMessage = `Market Hours for Mutual Funds: You are placing an order after market close and will receive the Net Asset Value of next business day ${nextBusinessDay}`;
            setTradingWindowWarning(false, warningMessage, nextBusinessDay);
        }
    };

    saveChildrenState = (data, index) => {
        const { resetPageNumber } = this.props;
        this.setState(prevState => {
            let { childrenState } = prevState;
            const { pageIndex } = prevState;
            if (
                index == 0 &&
                data.accountSelected &&
                childrenState.length &&
                data.accountSelected.accountNumber !== childrenState[0].accountSelected.accountNumber
            ) {
                childrenState = [];
                resetPageNumber();
            }

            if (index == 1 && childrenState[2]) {
                childrenState.splice(2, 1);
            }
            childrenState[+index] = data;
            return {
                childrenState,
                pageIndex,
            };
        });
    };

    handleError = bool => {
        if (bool) {
            this.setState({
                displayNotification: true,
                notificationMsg: { message: TransactionConstant.NOTIFICATION_ERROR_MESSAGE },
            });
        }
    };

    handleCancelExchange = () => {
        const { clearExchangeData } = this.props;
        this.setState({ cancelExchange: true });
        clearExchangeData();
    };

    handleDoNotCancelExchange = () => {
        const { setNotToCancelExchange } = this.props;
        setNotToCancelExchange();
    };

    cancelCostBasisModal = () => {
        this.setState({ showCostBasisWarningMessage: false });
    };

    showModalBasedOnCostBasis = costBasis => {
        if (costBasis.toLowerCase() == 'identified lot' || costBasis.toLowerCase() == 'specified lot') {
            this.setState({ showCostBasisWarningMessage: true, selectedFundCostBasis: costBasis });
        } else {
            this.setState({ showCostBasisWarningMessage: false, selectedFundCostBasis: costBasis });
        }
    };

    onSwitchComponent = (bool, stateData = {}, pageIndex = '') => () => {
        const { savePageState, clearFundAccountList, exchangeData } = this.props;
        let validMasterRegAddress = true;
        if (pageIndex == 0 && bool) {
            validMasterRegAddress = !(
                stateData &&
                stateData.accountSelected &&
                stateData.accountSelected.isForeignAddress == 'Y'
            );
            this.setState({
                showMasterRegForeignAddressModal: !validMasterRegAddress,
                addressValid: validMasterRegAddress,
            });
        }
        if (validMasterRegAddress) {
            if (bool) {
                this.saveChildrenState(stateData, pageIndex);
                savePageState({ data: stateData, pageIndex });
                if (pageIndex == 1 || pageIndex == 0) {
                    clearFundAccountList();
                }
            }
            if (
                pageIndex == 3 &&
                exchangeData.page2.selectedFund &&
                exchangeData.page2.selectedFund.fundAccountNumber
            ) {
                this.submitData();
            } else if (pageIndex == 21) {
                // Handle fund Summary
                this.setState(prevState => {
                    return {
                        pageIndex: 21,
                        displayNotification: false,
                        notificationMsg: {},
                        prevPageIndex: prevState.pageIndex,
                    };
                });
            } else {
                this.updateStateOnSwitch(bool, pageIndex,stateData);
            }
        }
    };

    updateStateOnSwitch = (bool, pageIndex,stateData) => {
        this.setState(prevState => {
            if (prevState.pageIndex === 21 && pageIndex !== '') {
                return {
                    pageIndex,
                    displayNotification: false,
                    notificationMsg: {},
                };
            }
    
            if (prevState.pageIndex === 2 && bool) {
                return this.handleAmountCheck(stateData)
                    ? this.getUpdatedState(prevState, bool)
                    : { showConfirmModal: true };
            }
    
            return this.getUpdatedState(prevState, bool);
        });
    };

    getUpdatedState = (prevState, bool) => {
        return {
            pageIndex: bool ? prevState.pageIndex + 1 : prevState.pageIndex - 1,
            displayNotification: false,
            notificationMsg: {},
        };
    };

    cancelForeignAddressWarningMessage = () => {
        this.setState({ showMasterRegForeignAddressModal: false });
    };

    onCancel = () => {
        const { history } = this.props;
        history.push({
            pathname: '/transactionDashboard',
            state: {
                accLiquidated: false,
                displayNotification: false,
            },
        });
    };

    navToNextPage = () => () => {
        this.setState({
            pageIndex: 5,
            childrenState: [],
        });
    };

    scrollToSection = (index, section) => () => {
        this.editSection = section;
        if (index !== undefined) {
            this.setState(
                () => {
                    return {
                        pageIndex: index,
                    };
                },
                () => {
                    this.editSection = null;
                },
            );
        }
    };

    getTypeValueReq = ({ amtType, sellingamt, unissuedBalance }) => {
        let typeValueReq = 'A';
        if (parseFloat(sellingamt) !== parseFloat(Number(unissuedBalance).toFixed(2))) {
            if (amtType == 'dollaramt') {
                typeValueReq = 'D';
            } else if (amtType == 'percentamt') {
                typeValueReq = 'P';
            } else if (amtType == 'percentamtshare') {
                typeValueReq = 'S';
            }
        }
        return typeValueReq;
    };

    getFormattedSellingValue = (value, amtType) => {
        let appendedZero = '00';
        let fixedDigit = 2;
        if (amtType == 'percentamtshare') {
            appendedZero = '000';
            fixedDigit = 3;
        }
        const sellingValue = `${parseFloat(value).toFixed(fixedDigit)}`.split('.');
        let finalSellingValue = `${sellingValue[0]}${appendedZero}`;
        if (sellingValue[1] > 0) {
            finalSellingValue = `${sellingValue[0]}${sellingValue[1]}`;
        }
        return finalSellingValue;
    };

    getExchangeAmount = ({ amtType, sellingamt, unissuedBalance, userinputpercent, unissuedShares }) => {
        let exchangeAmount = '';
        if (parseFloat(sellingamt) !== parseFloat(Number(unissuedBalance).toFixed(2))) {
            if (amtType == 'dollaramt') {
                exchangeAmount = this.getFormattedSellingValue(sellingamt);
            } else if (amtType == 'percentamt') {
                exchangeAmount = this.getFormattedSellingValue(userinputpercent);
            } else if (amtType == 'percentamtshare') {
                const calculatedShares = ((Number(userinputpercent) * Number(unissuedShares)) / 100).toFixed(3);
                exchangeAmount = this.getFormattedSellingValue(calculatedShares, amtType);
            }
        }
        return exchangeAmount;
    };

    checkObjectAndGetData = (exchangeData,parameterName,pageNumber) =>
    {
        if(pageNumber == 'page1')
        {
            return (exchangeData.page1 && exchangeData.page1.selectedFund[`${parameterName.toString()}`] ? exchangeData.page1.selectedFund[`${parameterName.toString()}`]:'')
        }
        else
        {
            return (exchangeData.page2 && exchangeData.page2.selectedFund[`${parameterName.toString()}`] ? exchangeData.page2.selectedFund[`${parameterName.toString()}`]:'')
        }
    }


    submitData = () => {
        if (isTokenAvailable() == null) {
            this.setState({ showAuthBox: true });
        } else {
            const { submitExchangeData, exchangeData, tradingWindowData } = this.props;
            this.setTradingWarning();
            const fundNumberFrom = this.checkObjectAndGetData(exchangeData,'fundNumber','page1');

            const fundAccountNumberFrom = this.checkObjectAndGetData(exchangeData,'fundAccountNumber','page1');

            const liquidityNoticeFlagFrom = this.checkObjectAndGetData(exchangeData,'liquidityNoticeFlag','page1');

            const fundNumberTo = this.checkObjectAndGetData(exchangeData,'fundNumber','page2');

            const fundAccountNumberTo = this.checkObjectAndGetData(exchangeData,'fundAccountNumber','page2');

            const liquidityNoticeFlagTo = this.checkObjectAndGetData(exchangeData,'liquidityNoticeFlag','page2');

            const { amtType, sellingamt, unissuedBalance, userinputpercent, unissuedShares } =
                exchangeData.page1 && exchangeData.page1.selectedFund;

            const submitPayload = {
                companyNumber: TransactionConstant.USAA_FUND_COMPANY_NUMBER.companyNumber,
                fundNumberFrom,
                fundAccountNumberFrom,
                fundNumberTo,
                fundAccountNumberTo,
                liquidityNoticeFlag: getDataFromORCondition(liquidityNoticeFlagFrom , liquidityNoticeFlagTo),
                newAccountFlag: getValidValueOf(fundAccountNumberTo,'N','Y'),
                typeValueReq: this.getTypeValueReq({ amtType, sellingamt, unissuedBalance }),
                exchangeValue: this.getExchangeAmount({
                    amtType,
                    sellingamt,
                    unissuedBalance,
                    userinputpercent,
                    unissuedShares,
                }),
                reinvestEarnings: exchangeData.page2 && exchangeData.page2.ReInvest ? 'Y' : 'N',
                effectiveDate: `${tradingWindowData.effectiveDate}`.split(' ')[0],
                timeSubmitted: new Date(),
                marketEndTime: tradingWindowData.tradingEndTime,
            };

            if (exchangeData.page2 && !exchangeData.page2.ReInvestSwitchOn) {
                delete submitPayload.reinvestEarnings;
            }
            if (!fundAccountNumberTo) {
                let econsentDocumentLink = EConsentData.filter(item => item.fundCode == fundNumberTo);
                if (econsentDocumentLink.length == 0) {
                    econsentDocumentLink = [
                        {
                            docUrl: '',
                            formId: '',
                            description: '',
                            fileName: '',
                            fundCode: '',
                        },
                    ];
                }
                const { docUrl, formId, description, fileName, fundCode } = econsentDocumentLink[0];
                submitPayload.document = {
                    docType: 'pdf',
                    docUrl,
                    formId,
                    description,
                    fileName,
                    fundCode,
                    eSignDate: exchangeData.eConsentTime,
                };
            }
            submitExchangeData({ submitPayload, token: getToken() });
            this.navToNextPage()();
        }
    };

    isIRA = () => {
        const { exchangeData } = this.props;
        const { accountType } = exchangeData.page0 && exchangeData.page0.accountSelected;
        const trimAccountType = accountType.trim();
        return (
            trimAccountType == TransactionConstant.TRADITIONAL_IRA ||
            trimAccountType.toLowerCase().includes(TransactionConstant.ROTH) ||
            trimAccountType == TransactionConstant.ROTH_IRA ||
            trimAccountType == TransactionConstant.SEP_IRA ||
            trimAccountType.toLowerCase().includes(TransactionConstant.COVERDELL_EDUCATION_IRA) ||
            trimAccountType == TransactionConstant.ROLLOVER_IRA ||
            trimAccountType == TransactionConstant.GUARDIAN_IRA ||
            trimAccountType == TransactionConstant.SIMPLE_IRA ||
            trimAccountType == TransactionConstant.BENEFICIARY_IRA ||
            trimAccountType == TransactionConstant.OPTIONAL_RETIREMENT_IRA ||
            trimAccountType == TransactionConstant.PENSION_IRA ||
            trimAccountType == TransactionConstant.ROTH_CONVERSION_IRA ||
            trimAccountType == TransactionConstant.SIMPLE_IRA_DFI ||
            trimAccountType == TransactionConstant.SIMPLE_IRA_NON_DFI
        );
    };

    bindComponent() {
        const { pageIndex, prevPageIndex, childrenState, addressValid, showMasterRegForeignAddressModal } = this.state;
        const {
            exchangeData,
            getFunds,
            getCompositeData,
            localStateData,
            masterLookupStateData,
            getFundDetails,
            accOpeningReducerData,
            getFundListData,
            purchaseReducerData,
            getAccountsList,
            submitExchangeData,
            setNextPageNumber,
            tradingWindowData,
            location,
            setSelectedConsent,
            resetEConsent,
            setConsentTime,
            masterRegistrationAccountData,
            setCancelExchange,
            setNextPageNumberPositions,
            fundAccountData,
            getTransHolderList,
            transactionHolderData,
        } = this.props;
        const [accountSelectionState] = childrenState;
        const { accountSelected } = accountSelectionState || {};

        const navigationObj = {
            onCancel: this.onCancel,
            onPrimaryClick: this.onSwitchComponent(true, this.state, pageIndex),
            onSecondaryClick: this.onSwitchComponent(false),
            arialabelback: '',
            arialabelnext: '',
            portalRoot: this.bottomNav,
        };
        const content = {
            currentValTxt: TransactionConstant.CURRENT_VAL_TEXT,
            holdingTxt: TransactionConstant.TOTAL_SHARES_TEXT,
            planNameTxt: TransactionConstant.AIP_TEXT,
        };
        const transactionType = 'exchange';
        switch (pageIndex) {
            case 1: {
                return (
                    <ErrorBoundary>
                        <FundSelection
                            getFunds={getFunds}
                            navigationObj={navigationObj}
                            savedState={childrenState[+pageIndex]}
                            saveChildrenState={this.saveChildrenState}
                            onSwitchComponent={this.onSwitchComponent}
                            pageIndex={pageIndex}
                            transactionType={transactionType}
                            setTradingWarning={this.setTradingWarning}
                            setNextPageNumber={setNextPageNumber}
                            location={location}
                            setCancelExchange={setCancelExchange}
                            setNextPageNumberPositions={setNextPageNumberPositions}
                            getFundDetails={getFundDetails}
                            showModalBasedOnCostBasis={this.showModalBasedOnCostBasis}
                            getTransHolderList={getTransHolderList}
                        />
                    </ErrorBoundary>
                );
            }
            case 2: {
                return (
                    <ErrorBoundary>
                        <InvestmentSelection
                            navigationObj={navigationObj}
                            accDetails={accountSelected}
                            savedState={childrenState[+pageIndex]}
                            saveChildrenState={this.saveChildrenState}
                            onSwitchComponent={this.onSwitchComponent}
                            pageIndex={pageIndex}
                            editSection={this.editSection}
                            getFunds={getFunds}
                            getCompositeData={getCompositeData}
                            purchaseReducerData={purchaseReducerData}
                            localStateData={localStateData}
                            accOpeningData={accOpeningReducerData}
                            masterLookupStateData={masterLookupStateData}
                            getAccFundsData={getFundListData}
                            getFundDetails={getFundDetails}
                            setTradingWarning={this.setTradingWarning}
                            setNextPageNumber={setNextPageNumber}
                            setCancelExchange={setCancelExchange}
                            isIRA={this.isIRA}
                            transactionType={transactionType}
                        />
                    </ErrorBoundary>
                );
            }
            case 21: {
                return (
                    <ErrorBoundary>
                        <FundSummary
                            navigationObj={navigationObj}
                            accDetails={accountSelected}
                            savedState={childrenState[+pageIndex]}
                            saveChildrenState={this.saveChildrenState}
                            onSwitchComponent={this.onSwitchComponent}
                            pageIndex={prevPageIndex}
                            accOpeningData={accOpeningReducerData}
                            fundAccountData={fundAccountData}
                            transactionType={transactionType}
                        />
                    </ErrorBoundary>
                );
            }
            case 3: {
                return (
                    <ErrorBoundary>
                        <ExchangeReview
                            accDetails={accountSelected}
                            savedState={childrenState}
                            onSwitchComponent={this.onSwitchComponent}
                            navToNextPage={this.navToNextPage}
                            pageIndex={pageIndex}
                            scrollToSection={this.scrollToSection}
                            exchangeActions={exchangeData}
                            submitExchangeData={submitExchangeData}
                            setTradingWarning={this.setTradingWarning}
                            tradingWindowData={tradingWindowData}
                            setCancelExchange={setCancelExchange}
                            transactionHolderData={transactionHolderData}
                        />
                    </ErrorBoundary>
                );
            }
            case 4: {
                return (
                    <ErrorBoundary>
                        <EConsent
                            onSwitchComponent={this.onSwitchComponent}
                            submitData={this.submitData}
                            selectedConsent={exchangeData.selectedConsent}
                            setSelectedConsent={setSelectedConsent}
                            selectedFund={exchangeData.page2 && exchangeData.page2.selectedFund}
                            resetEConsent={resetEConsent}
                            setConsentTime={setConsentTime}
                            setCancelExchange={setCancelExchange}
                            transactionType={transactionType}
                        />
                    </ErrorBoundary>
                );
            }
            case 5: {
                const { isLoading, isError } = exchangeData;
                const selectedFund = exchangeData.page2 && exchangeData.page2.selectedFund;
                const masterRegId = exchangeData.page0 && exchangeData.page0.accountSelected.accountNumber;
                return (
                    <ErrorBoundary>
                        <ConfirmationComponent
                            setTradingWarning={this.setTradingWarning}
                            isLoading={isLoading}
                            isError={isError}
                            handleError={this.handleError}
                            selectedFund={selectedFund}
                            masterRegId={masterRegId}
                            isIRA={this.isIRA()}
                            isExchange
                            transactionType={transactionType}
                        />
                    </ErrorBoundary>
                );
            }
            default:
                return (
                    <ErrorBoundary>
                        <AccountSelection
                            savedState={childrenState[+pageIndex]}
                            saveChildrenState={this.saveChildrenState}
                            navigationObj={navigationObj}
                            onSwitchComponent={this.onSwitchComponent}
                            getAccountsList={getAccountsList}
                            pageIndex={pageIndex}
                            content={content}
                            isTransaction
                            transactionType={transactionType}
                            accountDetails={masterRegistrationAccountData.masterRegAccountList}
                            setCancelExchange={setCancelExchange}
                            addressValid={addressValid}
                            showMasterRegAddrChgModal={showMasterRegForeignAddressModal}
                        />
                    </ErrorBoundary>
                );
        }
    }

    authBox = () => {
        this.setState({ showAuthBox: true });
    };

    moveNextPage = () => {
        this.setState({ showAuthBox: false });
    };

    handleAmountCheck = (stateData) => {
        const { exchangeData } = this.props;
        const selectedFund = exchangeData?.page1?.selectedFund;
        if (!selectedFund) return;
        const { sellingamt, minimumAutomaticInvestment } = selectedFund;
        const minimumInvestment = stateData?.selectedFund?.fundAccountNumber ? minimumAutomaticInvestment : stateData?.selectedFund?.initialInvestment
        let isValid = true
       
        if (removeComma(parseFloat(sellingamt)) < removeComma(parseFloat(minimumInvestment))) {
            isValid = false
        }
        return isValid
    };

    handleCancelPopup = () =>{
        this.setState({
            showConfirmModal:false
        })
    }

    handleProceed = () =>{
        this.setState(prevState => {
            return {
                showConfirmModal:false,
                pageIndex:prevState.pageIndex + 1,
                displayNotification: false,
                notificationMsg: {},
            }; 
        });
    }

    render() {
        const {
            pageIndex,
            displayNotification,
            notificationMsg,
            showCancelExchangeModal,
            cancelExchange,
            showMasterRegForeignAddressModal,
            showCostBasisWarningMessage,
            selectedFundCostBasis,
            showAuthBox,
            showConfirmModal
        } = this.state;
        const { tradingWindowData, exchangeData } = this.props;
        const pageHeading = 'Exchange';
        const isInitialExchange = !(
            exchangeData &&
            exchangeData.page2 &&
            exchangeData.page2.selectedFund &&
            exchangeData.page2.selectedFund.fundAccountNumber
        );
        const cancelTransactionText = TransactionConstant.CANCEL_TRANSACTION_DESCRIPTION_GENERAL;

        return (
            <div>
                {cancelExchange ? <Redirect to="/accountholder" /> : ''}
                {!tradingWindowData.canTrade && (
                    <WBlueBanner text={tradingWindowData.tradingClosureMsg} onClose={this.onNotificationClose} />
                )}
                {displayNotification && <WBlueBanner text={notificationMsg} onClose={this.onNotificationClose} />}
                {<Authenticate showAuthBox={showAuthBox} moveNextPage={this.moveNextPage} />}

                <ConfirmationMessage
                    modalTitle={TransactionConstant.CANCEL_TRANSACTION_TEXT}
                    modalBodyText={cancelTransactionText}
                    primaryButtonText="Yes"
                    secondaryButtonText="No"
                    onPrimaryClick={this.handleCancelExchange}
                    onSecondaryClick={this.handleDoNotCancelExchange}
                    show={showCancelExchangeModal}
                />

                {showMasterRegForeignAddressModal && (
                    <ConfirmModal
                        modalTitle={TransactionConstant.INSTRUCTIONS_TEXT}
                        modalBodyText={TransactionConstant.FOREIGN_ADDRESS_TEXT}
                        primaryButtonText={TransactionConstant.OK_TEXT}
                        onPrimaryClick={this.cancelForeignAddressWarningMessage}
                    />
                )}

                {showConfirmModal && (
                    <ConformationModal
                        modalTitle={TransactionConstant.MODAL_HEADING}
                        modalBodyText={TransactionConstant.MODAL_BODY_TEXT}
                        primaryButtonText="Proceed"
                        onPrimaryClick={this.handleProceed}
                        secondaryButtonText={"Cancel"}
                        onSecondaryClick={this.handleCancelPopup}
                    />
                )}
                {showCostBasisWarningMessage && (
                    <ConfirmModal
                        modalTitle={TransactionConstant.INSTRUCTIONS_TEXT}
                        modalBodyText={`${TransactionConstant.TAX_ACCOUNTING_METHOD_ERROR_MSG1}"${selectedFundCostBasis}"${TransactionConstant.TAX_ACCOUNTING_METHOD_ERROR_MSG2}`}
                        primaryButtonText={TransactionConstant.OK_TEXT}
                        onPrimaryClick={this.cancelCostBasisModal}
                    />
                )}
                <div style={styles.containerStyle}>
                    {pageIndex !== 5 && pageIndex !== 21 && (
                        <>
                            <WStepperMobile
                                style={stepperStyle}
                                currentPage={pageIndex}
                                pages={isInitialExchange ? styles.pages : styles.subsequentExchangePages}
                                transactionType="Exchange - "
                            />
                        </>
                    )}
                    <div className="container mobile-cont">
                        <WBreadCrumb activeCrumb={styles.activeCrumb} breadCrumbItems={styles.bredCrumItmes} />

                        {pageIndex !== 5 && pageIndex !== 21 && (
                            <>
                                <styles.FlexDiv>
                                    <PageHeading>{pageHeading}</PageHeading>
                                </styles.FlexDiv>
                                <styles.RwdFlexDiv>
                                    <PageHeading aria-hidden="true">{pageHeading}</PageHeading>
                                </styles.RwdFlexDiv>
                                <Stepper
                                    className="desktopStepper"
                                    style={stepperStyle}
                                    currentPage={pageIndex}
                                    pages={isInitialExchange ? styles.pages : styles.subsequentExchangePages}
                                />
                            </>
                        )}
                    </div>

                    {this.bindComponent()}
                    <div ref={this.bottomNav} />
                </div>
            </div>
        );
    }
}

ExchangeMainComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    exchangeData: PropTypes.instanceOf(Object),
    getFunds: PropTypes.func,
    clearFundAccountList: PropTypes.func,
    getCompositeData: PropTypes.instanceOf(Object),
    localStateData: PropTypes.instanceOf(Object),
    masterLookupStateData: PropTypes.instanceOf(Object),
    getFundDetails: PropTypes.func,
    getFundListData: PropTypes.instanceOf(Object),
    accOpeningReducerData: PropTypes.instanceOf(Object),
    purchaseReducerData: PropTypes.instanceOf(Object),
    getAccountsList: PropTypes.func,
    savePageState: PropTypes.func,
    submitExchangeData: PropTypes.func,
    setTradingWindowWarning: PropTypes.func,
    tradingWindowData: PropTypes.instanceOf(Object),
    setNextPageNumber: PropTypes.func,
    resetPageNumber: PropTypes.func,
    getTradingClosureWindow: PropTypes.func,
    location: PropTypes.instanceOf(Object),
    setSelectedConsent: PropTypes.func,
    masterRegistrationAccountData: PropTypes.shape({
        masterRegAccountList: PropTypes.instanceOf(Object),
    }).isRequired,
    resetEConsent: PropTypes.func,
    setConsentTime: PropTypes.func,
    clearExchangeData: PropTypes.func,
    setCancelExchange: PropTypes.func,
    setNotToCancelExchange: PropTypes.func,
    clearMasterRegInfo: PropTypes.func,
    clearLiquidationData: PropTypes.func,
    setNextPageNumberPositions: PropTypes.func,
    fundAccountData: PropTypes.instanceOf(Object),
    getTransHolderList: PropTypes.func,
    transactionHolderData: PropTypes.instanceOf(Object),
};
ExchangeMainComponent.defaultProps = {
    getCompositeData: null,
    localStateData: null,
    masterLookupStateData: null,
    getFundDetails: () => {},
    getFundListData: null,
    accOpeningReducerData: null,
    purchaseReducerData: null,
    exchangeData: {},
    getAccountsList: () => {},
    savePageState: () => {},
    getFunds: () => {},
    clearFundAccountList: () => {},
    submitExchangeData: () => {},
    setTradingWindowWarning: () => {},
    tradingWindowData: {},
    setSelectedConsent: () => {},
    setNextPageNumber: () => {},
    resetPageNumber: () => {},
    getTradingClosureWindow: () => {},
    location: {},
    resetEConsent: () => {},
    setConsentTime: () => {},
    clearExchangeData: () => {},
    setCancelExchange: () => {},
    setNotToCancelExchange: () => {},
    clearMasterRegInfo: () => {},
    clearLiquidationData: () => {},
    setNextPageNumberPositions: () => {},
    fundAccountData: {},
    getTransHolderList: () => {},
    transactionHolderData: {},
};

export const mapStateToProps = (state /* , props */) => ({
    exchangeData: state.exchangeData,
    localStateData: state.localStateManagementReducerData,
    masterLookupStateData: state.masterLookUpData,
    masterRegistrationAccountData: state.masterRegistrationAccountData,
    accOpeningReducerData: state.accOpeningReducerData,
    purchaseReducerData: state.purchaseData,
    tradingWindowData: state.tradingWindowData,
    fundAccountData: state.fundAccountData,
    transactionHolderData: state.transactionHolderData,
});

export const mapDispatchToProps = {
    ...exchangeActions,
    ...fundAccountActions,
    getCompositeData: accOpeningActions.getCompositeLookUpData,
    getFundListData: accOpeningActions.getFundListData,
    getFundDetails: accOpeningActions.getFundDetailsData,
    getAccountsList: masterRegistrationAccountActions.getAccountsList,
    clearMasterRegInfo: masterRegistrationAccountActions.clearMasterRegInfo,
    savePageState: exchangeActions.savePageState,
    getFunds: fundAccountActions.getFundAccountsList,
    clearFundAccountList: fundAccountActions.clearFundAccountList,
    submitExchangeData: exchangeActions.submitExchangeData,
    setTradingWindowWarning: tradingWindowActions.setTradingWindowWarning,
    setNextPageNumber: exchangeActions.setNextPageNumber,
    resetPageNumber: exchangeActions.resetPageNumber,
    getTradingClosureWindow: tradingWindowActions.getTradingClosureWindow,
    setSelectedConsent: exchangeActions.setSelectedConsent,
    setSelectedProspectus: exchangeActions.setSelectedProspectus,
    setSelectedDisclosure: exchangeActions.setSelectedDisclosure,
    resetEConsent: exchangeActions.resetEConsent,
    setConsentName: exchangeActions.setConsentName,
    setConsentTime: exchangeActions.setConsentTime,
    clearExchangeData: exchangeActions.clearExchangeData,
    setCancelExchange: exchangeActions.setCancelExchange,
    setNotToCancelExchange: exchangeActions.setNotToCancelExchange,
    clearLiquidationData: liquidationActions.clearLiquidationData,
    setNextPageNumberPositions: exchangeActions.setNextPageNumberPositions,
    getTransHolderList: transactionHistoryActions.getTransHolderList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeMainComponent);
