import { getTokenFromSession } from '../../../../shared/Helpers/Utils';
import * as ActionTypes from '../../../../shared/ReduxConstants/ServiceActionConstants';
import * as TransactionConstants from '../../TransactionConstants';
import { connect } from 'react-redux';
import ErrorBoundary from '../../Common/ErrorBoundary/errorboundary';
import FundInvest from './FundInvestmentComponent';
import iconDown from '../../../../assets/Icon_Down.svg';
import TransactionInvestAccountCardComponent from '../../../../common/TransactionInvestAccountCard/TransactionInvestAccountCardComponent';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import queryString from 'query-string';
import { WButton, WSpinner, AccountInfoCard, Accordian, AccordianTitle,FeeGatesWarningModal } from '../../../../common';
import { removeComma } from 'common/Formatter/CurrencyFormatter';
import { addNewElementIntoArray, checkArrayLengthAndReturnValue, getValidValue, getValidValueOf, identifyExsitingAccountWithInitialInvestment, identifyIsProportionate } from 'commonHelper/CommonHelperFunctions';
import ProportionateCardComponent from 'common/TransactionInvestAccountCard/ProportionateCardComponent';
import { getDataFromORCondition } from 'utils/utils';
import { ConformationModal } from '../../../../common';

// Styled components
const ButtonStyle = styled.button`
    width: 150px;
    height: 50px;
    opacity: 1;
    text-align: center;
    font: 500 16px/22px Benton Sans;
    letter-spacing: 0;
    border: 1px solid #61285f45;
    &:disabled {
        opacity: 0.5;
    }
`;

const LoadMoreSpan = styled.button`
    margin-right: 5px;
    background: #ffffff 0% 0% no-repeat padding-box;
    font-weight: bold;
    border: 0;
    color: #004a98;
    font: 800 16px/20px yorkten-slab-normal;
`;

const LoadMoreDiv = styled.div`
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: benton-sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #004a98;
    cursor: pointer;
`;

const EmptyAccountsP = styled.p`
    font: 500 16px/22px Benton Sans;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SecondaryButton = styled(ButtonStyle)`
    background: #ffffff 0% 0% no-repeat padding-box;
    color: #544a54;
`;
SecondaryButton.displayName = 'SecondaryButton';

const PrimaryButton = styled(ButtonStyle)`
    background: #544a54 0% 0% no-repeat padding-box;
    color: #ffffff;
`;
PrimaryButton.displayName = 'PrimaryButton';

const assignObj = obj => {
    return obj;
};

const AccountCard = {
    marginTop: '40px',
    marginBottom: '40px',
};


const validFieldErrorTxt = TransactionConstants.NO_INPUT_VALIDATION_ERROR_MESSAGE;
const startDateValidFieldErrorTxt = TransactionConstants.START_DATE_INPUT_VALIDATION_ERROR_MESSAGE;

const cancelButtonStyles = {
    color: 'rgb(0, 74, 152)',
    marginBottom: '15px',
    marginTop: '10px'
};

const MainDiv = styled.div`
    margin-bottom: 30px;
    margin-top: 3px;
    background: #fafafa 0% 0% no-repeat padding-box;
    border: 1px solid #efeded;
    display: flex;
    align-items: center;
    box-sizing: border-box;
`;

const BtnWrap = styled(Container)`
    display:flex; 
    justify-content: space-between;
    align-items:center;
    padding:20px 0;
    &>:nth-child(2){ 
        margin-left: auto;
        margin-right: 50px;
        button{
            margin-bottom: 0 !important;
        }
    }
    @media (max-width:767px){
        &>:nth-child(1){
            order:3;
        }
        &>:nth-child(2){
            order:2;
            margin: 0;
            button{
                margin:0 !important;
            } 
        }
        &>:nth-child(3){
            order:1;
            width:100%;
            button{
                width: 100% !important;
            }
        }
        &>:not(:first-child){
            margin-bottom:20px;
        }
        flex-direction:column;
    }
`;

class InvestmentSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fundsAvailable: [],
            showInvestFund: false,
            selectedAccounts: [],
            totalSubAmt: 0,
            totalMnlyInv: 0,
            startDate: '',
            errors: {
                subInvest: '',
                mnthlyInvest: '',
                startDate: '',
            },
            fields: {
                initialInvestValid: false,
                monthlyInvestValid: false,
                startDateValid: false,
            },
            fundOptionSelected: 'init',
            masterLookupData: {},
            showMnthlyInvst: false,
            displayNotification: false,
            notificationMsg: '',
            showErrorModal: false,
            modalAlertMessage: '',
            disabled: true,
            retrivedState: false,
            isLoading: false,
            isError: false,
            setFundClickSelection: false,
            removeBtnClicked: false,
            showWarningDialog:false,
            touchedIndex:0,
            touchedFundName:'fundName',
            touchedFundPercentValue:'0%',
            touchedFundNo:'',
            touchedFundAccNum:'',
            isFee:false,
            fundListwithPercentage:[],
            proportianteSelectedFundsList:[],
            isProportinateSelected:false,
            showConfirmModal:false

        };
        this.exchangeInvSelectionRef = React.createRef();
    }

    static getFundDetailsFormSavedState = (proportionateFaildFundList,fundListData) =>
    {
        if(proportionateFaildFundList?.length == 1)
        {
            const selectedFundList = fundListData.map((item)=>{

                if(item.fundAccountNumber == proportionateFaildFundList[0]?.fundAccountNumber)
                {
                    return {
                        ...item,
                        checked:true
                    }
                }
                else
                {
                    return {
                        ...item,
                        checked:false
                    }
                }
            })

            return selectedFundList;
        }
        else
        {
            return fundListData;
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            savedState,
            masterLookupStateData,
            fundAccountData,
            purchaseData,
            onSwitchComponent,
            location,
            accOpeningData,
            logoutRouteData,

        } = nextProps;

        const { search } = getDataFromORCondition(logoutRouteData,{})
        const { fundid = '' } = search ? queryString.parse(search) : queryString.parse(location.search)
        const { fundAccountList = [] } = fundAccountData;

        const { fundsAvailable, fundOptionSelected,selectedAccounts,showInvestFund,isProportinateSelected,totalSubAmt } = prevState;
        let setFundClickSelection = false;

        if (!prevState.retrivedState && savedState) {
            return {
                ...savedState,
                retrivedState: true,
                fundsAvailable: savedState.fundsAvailable.length ? savedState.fundsAvailable : fundAccountList,
                fundOptionSelected: savedState.fundOptionSelected ? savedState.fundOptionSelected : fundOptionSelected,
                isError: masterLookupStateData.isError || fundAccountData.isError,
            };
        }else{
            console.log('Negetive cases for test cases')
        }


        if (purchaseData.goToNextPage) {
            onSwitchComponent(true, prevState, 1)();
        }else{
            console.log('Negetive cases for test cases')
        }

        let fundsAvailableTemp = [];
        const isLoading = getDataFromORCondition(fundAccountData.isLoading,accOpeningData.isLoading);
        if (isLoading !== prevState.isLoading) {
            fundsAvailableTemp = fundAccountList;
        } else {
            fundsAvailableTemp = fundsAvailable.length ? fundsAvailable : fundAccountList;
        }
        // Pre select fund based on location fundNo
        if ((location.state && location.state.fundNumber && fundsAvailableTemp.length > 0) || (fundid && fundsAvailableTemp.length > 0)) {
            setFundClickSelection = true;
        }else{
            console.log('Negetive cases for test cases')
        }

        return {
            ...prevState,
            masterLookupData: masterLookupStateData,
            isLoading: getDataFromORCondition(fundAccountData.isLoading,accOpeningData.isLoading),
            fundsAvailable: purchaseData?.isProportionateResubmitFlow ? InvestmentSelection.getFundDetailsFormSavedState(purchaseData?.proportionateFaildFundList,fundsAvailableTemp) : addNewElementIntoArray(fundsAvailableTemp),
            selectedAccounts:purchaseData?.proportionateFaildFundList?.length == 1 ? purchaseData?.proportionateFaildFundList : selectedAccounts,
            showInvestFund: purchaseData?.proportionateFaildFundList?.length == 1 ? true : showInvestFund,
            totalSubAmt: purchaseData?.proportionateFaildFundList?.length == 1 ? purchaseData?.proportionateFaildFundList[0].initialInvest : totalSubAmt,
            displayNotification: getDataFromORCondition(masterLookupStateData.isError,fundAccountData.isError),
            accountNumber: purchaseData.page0 && purchaseData.page0.displayDetails.accountSelection.data[1].value,
            accountName: purchaseData.page0 && purchaseData.page0.displayDetails.accountSelection.data[0].value,
            accountType: purchaseData.page0 && purchaseData.page0.displayDetails.accountSelection.data[2].value,
            accountTypeCode: getValidValue(purchaseData?.page0?.accountSelected?.accountTypeCode),
            socialCode: getValidValue(purchaseData?.page0?.accountSelected?.socialCode),
            fundOptionSelected:purchaseData?.proportionateFaildFundList?.length == 1 ? "sub" : fundOptionSelected,
            isProportinateSelected:purchaseData?.isProportionateResubmitFlow ? false : isProportinateSelected,
            isError: masterLookupStateData.isError || fundAccountData.isError,
            setFundClickSelection
        };
    }

    componentDidMount() {
        this.scrollToTop();
        sessionStorage.setItem('stepname', 'Investment Selection');
        document.getElementById('skipToMain')?.focus();
        const {
            savedState,
            editSection,
            getCompositeData,
            masterLookupStateData,
            getFunds,
            purchaseData,
            setTradingWarning,
            getTransHolderList,
        } = this.props;
        document.title = 'Investment Selection : Buy | Victory Capital';
        const { offsetTop = 0 } = {};

        // API call to get account nickname
        const masterId =
            purchaseData &&
            purchaseData.page0 &&
            purchaseData.page0.accountSelected &&
            purchaseData.page0.accountSelected.accountNumber;
        if (masterId) {
            const companyNumber = '591';
            const transactionHistoryPayload = {
                masterId: masterId,
                companyNumber,
            };

            getTransHolderList({
                payload: transactionHistoryPayload,
            });
        }else{
            console.log('')
        }

        if (editSection === TransactionConstants.ACCOUNT_SELECTION_TEXT) {
            window.scrollTo({
                top: offsetTop,
                behavior: TransactionConstants.BEHVIOUR_SMOOTH_VALUE,
            });
        }else{
            console.log('')
        }


        const payload = [];
        const compositePayloadData = TransactionConstants.compositePayload;
        for (let i = 0; i < compositePayloadData.length; i += 1) {
            const tempkey = compositePayloadData[parseInt(i, 10)];
            if (this.props && masterLookupStateData && !masterLookupStateData[tempkey.toString()]) {
                payload.push(tempkey);
            }
        }

        getCompositeData({ data: { datakey: payload }, token: getTokenFromSession() });

        if (!savedState) {
            const masterRegId = purchaseData.page0 && purchaseData.page0.displayDetails.accountSelection.data[1].value;
            const accountType = purchaseData.page0 && purchaseData.page0.displayDetails.accountSelection.data[2].value;
            const accountTypeCode =
                purchaseData.page0 &&
                purchaseData.page0.accountSelected &&
                purchaseData.page0.accountSelected.accountTypeCode;
            const socialCode =
                purchaseData.page0 &&
                purchaseData.page0.accountSelected &&
                purchaseData.page0.accountSelected.socialCode;

            getFunds({
                companyNumber: '591',
                masterRegId,
                accountType,
                socialCode,
                accountTypeCode,
            });
        }
        setTradingWarning();
    }

    componentDidUpdate = prevProps => {
        const { setFundClickSelection, fundsAvailable, selectedAccounts, removeBtnClicked } = this.state;
        const { location, purchaseData, logoutRouteData, setNoAccAvailFlag } = this.props;
        const masterRegId = purchaseData.page0 && purchaseData.page0.displayDetails.accountSelection.data[1].value;
        const { search } = logoutRouteData || {}
        const { fundid = '' } = search ? queryString.parse(search) : queryString.parse(location.search)
        if (this.props !== prevProps) {
            this.updateState(prevProps);
        }
        if (
            setFundClickSelection &&
            selectedAccounts.length === 0 &&
            location.state &&
            location.state.fundNumber &&
            Number(masterRegId) === Number(location.state.masterId)
            && this.props !== prevProps
        ) {
            const sfIndex = fundsAvailable.findIndex(
                i =>
                    Math.round(i.fundNumber) === Math.round(location.state.fundNumber) &&
                    Math.round(i.fundAccountNumber) === Math.round(location.state.fundAccountNumber),
            );
            if (sfIndex >= 0 && !removeBtnClicked) {
               const {fundNumber:fNo,fundAccountNumber:facNo} =fundsAvailable[+sfIndex]
               this.onFundListCheckboxClick(sfIndex, fNo ,facNo,true,undefined)
            }
        }

        // To get the Fund Preselected From Prelogin
        if (setFundClickSelection && selectedAccounts.length === 0 && fundid && this.props !== prevProps) {
            const sfIndex = fundsAvailable.findIndex(i => i.symbol === fundid);
            if (sfIndex >= 0 && !removeBtnClicked) {
               const {fundNumber:fNo,fundAccountNumber:facNo} =fundsAvailable[+sfIndex]
               setNoAccAvailFlag(false)
               this.onFundListCheckboxClick(sfIndex, fNo, facNo,true,undefined)
            } else {
                setNoAccAvailFlag(true)
            }
        }
    };

    componentWillUnmount() {
        const { setNoAccAvailFlag } = this.props
        setNoAccAvailFlag(false)
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: TransactionConstants.BEHVIOUR_SMOOTH_VALUE,
        });
    };

    changeSubInvHandler = (index, e, selector, dateValue) => {
        const { selectedAccounts } = this.state;
        const { handleError } = this.props;
        const updatedArray = [...selectedAccounts];
        const dateFormatText = 'MM/DD/YYYY';
        try {
            if (selector === 'startDate') {
                updatedArray[parseInt(index, 10)].startDate_touched = true;
                updatedArray[parseInt(index, 10)].startDate = moment(dateValue).format(dateFormatText);
                updatedArray[parseInt(index, 10)].startDatePickerValue = dateValue;
                const validatedObj = this.validateStartDate(updatedArray[parseInt(index, 10)]);
                this.setState({ errors: validatedObj.errObj });
            } else {
                const targetValue = removeComma(e.target.value);
                const values =targetValue ? targetValue.split('.') : [];
                const isInvalidInput = targetValue.match(/[!@#$%^&*(),?":{}|<>]/g);
                if ((isInvalidInput && isInvalidInput.length > 0) || values.length > 2) {
                    updatedArray[parseInt(index, 10)][`${e.target.name}_touched`] = true;
                } else if (values.length === 2 && values[1].length > 2) {
                    updatedArray[parseInt(index, 10)][`${e.target.name}_touched`] = true;
                    updatedArray[parseInt(index, 10)][e.target.name] = targetValue.substr(0, targetValue.length - 1);
                } else {
                    updatedArray[parseInt(index, 10)][`${e.target.name}_touched`] = true;
                    updatedArray[parseInt(index, 10)][e.target.name] = targetValue;
                }
            }
            this.calculateTotalInvestment(updatedArray);
            this.setState({ selectedAccounts: updatedArray });
        } catch (er) {
            handleError(true);
            this.setState({ displayNotification: true });
        }
    };

    removeFundHandler = fund => e => {
        try {
            e.stopPropagation();
            this.setState(prevState => {
                const { selectedAccounts, fundsAvailable } = prevState;
                const selectedAccounts1 = [...selectedAccounts];
                const list = [...fundsAvailable];

                const updatedAcc = selectedAccounts1.filter(accKey => accKey.fundNumber !== fund);

                for (let i = 0; i < list.length; i += 1) {
                    if (list[parseInt(i, 10)].fundNumber === fund) {
                        list[parseInt(i, 10)].checked = false;
                    }
                }

                if (updatedAcc.length === 0) {
                    return {
                        selectedAccounts: updatedAcc,
                        disabled: true,
                        totalSubAmt: 0,
                        totalMnlyInv: 0,
                        removeBtnClicked: true,
                    };
                }
                return {
                    selectedAccounts: updatedAcc,
                    totalSubAmt: 0,
                    totalMnlyInv: 0,
                };
            });
        } catch (error) {
            this.scrollToTop();
            this.setState({ displayNotification: true });
        }
    };

    calculateTotalInvestment = arr => {
        try {
            let totalInitial = 0;
            let totalMonthly = 0;
            for (let i = 0; i < arr.length; i += 1) {
                totalInitial = Number(removeComma(arr[parseInt(i, 10)].initialInvest));
                totalMonthly = Number(removeComma(arr[parseInt(i, 10)].monthlyInvest));
            }
            if (Number.isNaN(totalInitial)) {
                totalInitial = 0;
            } else if (Number.isNaN(totalMonthly)) {
                totalMonthly = 0;
            }

            this.setState({
                totalSubAmt: totalInitial,
                totalMnlyInv: totalMonthly,
                selectedAccounts: arr,
            });
        } catch (error) {
            this.setState({ displayNotification: true });
        }
    };    

    makeFundListCheckboxClick = sfIndex => {
        const { setNextPageNumberPositions } = this.props;
        this.setState(prevState => {
            const { fundsAvailable } = prevState;
            const list = [...fundsAvailable];
            const selectedFundsArr = [];
            const selectedFund = list.map((fund, index) => {
                const fundObj = { ...fund };
                if (index === sfIndex) {
                    fundObj.checked = true;
                } else {
                    fundObj.checked = false;
                }
                return fundObj;
            });
            selectedFundsArr.push(selectedFund[parseInt(sfIndex, 10)]);

            // Set page size based on selected fund index when redirected from view positions
            const pageNumber = (sfIndex + 1) / TransactionConstants.NUMBER_OF_FUNDS_PER_PAGE;
            if (parseInt(pageNumber, 10) > 0) {
                const fundPosition = (sfIndex + 1) % TransactionConstants.NUMBER_OF_FUNDS_PER_PAGE;
                let pageSize = parseInt(pageNumber, 10);
                if (fundPosition > 0) {
                    pageSize = parseInt(pageNumber, 10) + 1;
                }
                setNextPageNumberPositions(pageSize);
            }
            let fundOptionSelected = 'init';

            if (
                selectedFundsArr[0].fundAccountNumber &&
                selectedFundsArr[0].accountValue &&
                selectedFundsArr[0].unissuedShares
            ) {
                if (selectedFundsArr[0].accountValue > 0 && selectedFundsArr[0].unissuedShares >= 0) {
                    // scenario of purchase happened earlier this morning: Subsequent Purchase
                    fundOptionSelected = 'sub';
                } else if (selectedFundsArr[0].accountValue >= 0 && selectedFundsArr[0].unissuedShares > 0) {
                    // full liquidaiton occured earlier today: Subsequent Purchase
                    fundOptionSelected = 'sub';
                }
            }
            return {
                showWarningDialog:false,
                fundsAvailable: selectedFund,
                selectedAccounts: selectedFundsArr,
                showInvestFund: true,
                fundOptionSelected,
                disabled: false,
                showMnthlyInvst: false,
                totalInitial: Number(selectedFund[parseInt(sfIndex, 10)].initialInvest),
                totalMonthly: Number(selectedFund[parseInt(sfIndex, 10)].monthlyInvest),
                errors: {
                    subInvest: '',
                    mnthlyInvest: '',
                    startDate: '',
                },
            };
        });
    };


    onFundListCheckboxClick=(index, fundNo, fundAccNum,callSimulateFundListCheckboxClick,e)=>{        
        // logic to check if gate fund or fee fund if yes show warning else call onYesclick
        if(e){
            e.stopPropagation()
        }
        let showWarning=false
        let selectedFundName=""
        let feePercentValue=""
        let isFeeFlag=null
        const { fundsAvailable } = this.state;
        const feeFundIndex = fundsAvailable.findIndex(i => i.liquidityNoticeFlag === true && i.fundNumber === fundNo && i.fundAccountNumber === fundAccNum);
        const gateFundIndex=fundsAvailable.findIndex(i => i.redemptionNoticeFlag === true && i.fundNumber === fundNo && i.fundAccountNumber === fundAccNum);
        if(feeFundIndex>-1 || gateFundIndex>-1){
            showWarning=true
            if(feeFundIndex>-1){
                isFeeFlag=true
                selectedFundName=fundsAvailable[+feeFundIndex].fundName
                feePercentValue=fundsAvailable[+feeFundIndex].imposedFee
            }else if(gateFundIndex>-1){
                isFeeFlag=false
                selectedFundName=fundsAvailable[+gateFundIndex].fundName
            }
        }
        this.setState({
            showWarningDialog:showWarning,
            isFee:isFeeFlag,
            touchedFundPercentValue:feePercentValue,
            touchedFundName:selectedFundName,
            touchedIndex:index,
            touchedFundNo:fundNo,
            touchedFundAccNum:fundAccNum
        },()=>{
            if(!showWarning){

                if(callSimulateFundListCheckboxClick){
                    let selectedIndex=feeFundIndex>-1?feeFundIndex:index
                    this.makeFundListCheckboxClick(selectedIndex) 
                }else{
                    this.onYesFeeClick()
                }              
            }
        })
    }

    onGateClick=()=>{
        this.setState({showWarningDialog:false})
    }

    onNoFeeClick=event=>{
        event.preventDefault()
        this.setState((prevState)=>{          
            const {selectedAccounts,fundsAvailable,showInvestFund,touchedFundNo,touchedFundAccNum}=prevState
            const fundAvailableIndex = fundsAvailable.findIndex(i => i.fundNumber === touchedFundNo && i.fundAccountNumber === touchedFundAccNum);
            if(fundAvailableIndex>-1) {
                fundsAvailable[+fundAvailableIndex].selected=false
                fundsAvailable[+fundAvailableIndex].checked=false
            }            
            const sfIndex = selectedAccounts.findIndex(i => i.fundNumber === touchedFundNo && i.fundAccountNumber === touchedFundAccNum);
            if(sfIndex>-1) selectedAccounts.splice(sfIndex,1)
            return {
                showWarningDialog:false,
                selectedAccounts,fundsAvailable,showInvestFund:!!showInvestFund,
            }
        })
    }

    onYesFeeClick =event=> {
        this.setState(prevState => {
            const { fundsAvailable,touchedIndex:index,touchedFundNo:fundNo,touchedFundAccNum:fundAccNum } = prevState;
            const list = [...fundsAvailable];
            const selectedFundsArr = [];

            const selectedFund = list.map((fund, fundIndex) => {
                const fundObj = { ...fund };
                let fundOptionSelected = 'init';
                if(fundObj?.checked && fundObj?.fundName == 'Proportionate'){
                    fundOptionSelected = 'sub';
                }

                if (fundObj.fundAccountNumber && fundObj.accountValue && fundObj.unissuedShares) {
                    if (fundObj.accountValue > 0 && fundObj.unissuedShares >= 0) {
                        // scenario of purchase happened earlier this morning: Subsequent Purchase
                        fundOptionSelected = 'sub';
                    } else if (fundObj.accountValue >= 0 && fundObj.unissuedShares > 0) {
                        // full liquidaiton occured earlier today: Subsequent Purchase
                        fundOptionSelected = 'sub';
                    }
                }

                if (fundObj.fundNumber === fundNo && index === fundIndex && fundObj.fundAccountNumber === fundAccNum) {
                    fundObj.checked = true;
                    fundObj.fundOptionSelected = fundOptionSelected;
                    fundObj.initialInvest = '';
                    fundObj.monthlyInvest = '';
                    fundObj.startDate = '';
                    fundObj.initialInvest_touched = false;
                    fundObj.startDate_touched = false;
                    fundObj.monthlyInvest_touched = false;
                } else {
                    fundObj.checked = false;
                }
                return fundObj;
            });
            const sfIndex = selectedFund.findIndex(i => i.fundNumber === fundNo && i.fundAccountNumber === fundAccNum);
            selectedFundsArr.push(selectedFund[parseInt(sfIndex, 10)]);
            let fundOptionSelected = 'init';

            if (
                selectedFundsArr[0]?.fundAccountNumber &&
                selectedFundsArr[0]?.accountValue &&
                selectedFundsArr[0]?.unissuedShares
            ) {
                if (selectedFundsArr[0].accountValue > 0 && selectedFundsArr[0].unissuedShares >= 0) {
                    // scenario of purchase happened earlier this morning: Subsequent Purchase
                    fundOptionSelected = 'sub';
                } else if (selectedFundsArr[0].accountValue >= 0 && selectedFundsArr[0].unissuedShares > 0) {
                    // full liquidaiton occured earlier today: Subsequent Purchase
                    fundOptionSelected = 'sub';
                }
            }
            if(selectedFundsArr?.[0]?.checked && selectedFundsArr?.[0]?.fundName == 'Proportionate'){
                this.splitFundPercentage();
                fundOptionSelected = 'sub';
                selectedFundsArr[0].fundOptionSelected="sub";

            }
            return {
                showWarningDialog:false, 
                fundsAvailable: selectedFund,
                selectedAccounts: selectedFundsArr,
                showInvestFund: true,
                fundOptionSelected,
                disabled: false,
                showMnthlyInvst: false,
                totalInitial: Number(selectedFund[parseInt(sfIndex, 10)]?.initialInvest),
                totalMonthly: Number(selectedFund[parseInt(sfIndex, 10)]?.monthlyInvest),
                totalSubAmt: 0,
                errors: {
                    subInvest: '',
                    mnthlyInvest: '',
                    startDate: '',
                },
            };
        });
    };

    splitFundPercentage =()=>{
        const totalAmount = this.props?.transactionHolderData?.transactionList?.totalCurrentValue
        const fundList = JSON.parse(JSON.stringify(this.props?.transactionHolderData?.transactionList?.fundList));
        const removedZeroCurrentValueFundListArray = [];
        for(let i=0; i< fundList?.length; i++){
            
            if(parseFloat(fundList[i]?.currentValue) > 0)
            {
                let fundObj = fundList[i];
                fundObj['percentage'] =(parseFloat((fundList[i].currentValue /totalAmount) * 100 ))
                removedZeroCurrentValueFundListArray.push(fundObj);
            }
        }

        this.setState({
            fundListwithPercentage:removedZeroCurrentValueFundListArray
        })
    }

    filterFundObjectForProportionate = (fundAccountNumberValue) =>
    {
        const filteredFund = this.state.fundsAvailable?.filter((item)=> item.fundAccountNumber == fundAccountNumberValue);
        if(filteredFund?.length > 0)
        {
            return {
                ...filteredFund[0]
            }
        }
        else{
            return [];
        }
    }

    calculateFundRatio = (obj) =>
    {
        const minimumInvestmentAmount = 50;
        const {fundListwithPercentage} = this.state;
        let proportianteSelectedFundsList = [];
        const investmentAmount = parseFloat(removeComma(obj.initialInvest));
        let validFlag = true; 
        for(let i=0; i<fundListwithPercentage?.length;i++)
        {
            const percentageValue = parseFloat(fundListwithPercentage[i].percentage);
            const percentageAmount = (investmentAmount * (percentageValue/100)).toFixed(2);
            if(parseFloat(fundListwithPercentage[i].currentValue) > 0 && percentageAmount < minimumInvestmentAmount)
            {
                validFlag = false;
                break;
            }

            if(parseFloat(fundListwithPercentage[i].currentValue) > 0 && percentageAmount >= minimumInvestmentAmount)
            {
                let fundInfo = this.filterFundObjectForProportionate(fundListwithPercentage[i].fundAccountNumber);
                fundInfo['initialInvest'] = percentageAmount;
                proportianteSelectedFundsList.push(fundInfo)
            }
        }

        this.setState({proportianteSelectedFundsList:proportianteSelectedFundsList,
            isProportinateSelected:true})

        return validFlag;
    }


    validateInitialInvestment = obj => {
        const { errors, fields, fundOptionSelected } = this.state;
        const errObj = { ...errors };
        const fieldsObj = { ...fields };

        const initialInvestValBasedOnFundOptions = obj.fundAccountNumber
            ? this.formatInputValues(obj.minimumAutomaticInvestment)
            : this.formatInputValues(
                  fundOptionSelected === 'init' ? obj.initialInvestment : obj.minimumInitialMonthlyInvestment,
              );

        if(identifyExsitingAccountWithInitialInvestment(obj,fundOptionSelected) == false)
        {
            if (Object.keys(obj).indexOf('initialInvest') === -1 || obj.initialInvest === 0) {
                errObj.subInvest = validFieldErrorTxt;
                fieldsObj.initialInvestValid = false;
            } else if (+obj.initialInvest < initialInvestValBasedOnFundOptions) {
                errObj.subInvest = TransactionConstants.INITIAL_INVESTMENT_VALIDATION_ERROR_MESSAGE;
                fieldsObj.initialInvestValid = false;
            } else {
                errObj.subInvest = '';
                fieldsObj.initialInvestValid = true;
            }
        }
        else
        {
            errObj.subInvest = '';
            fieldsObj.initialInvestValid = true;
        }
       
        if(obj?.checked && obj?.fundName == 'Proportionate'){
            if(this.calculateFundRatio(obj) == false)
            {
                errObj.subInvest = TransactionConstants.INITIAL_INVESTMENT_VALIDATION_ERROR_MESSAGE;
                fieldsObj.initialInvestValid = false;
            }
        }
        else
        {
            this.setState({isProportinateSelected:false})
        }
        
        return {
            errObj,
            fieldsObj,
        };
    };

    validateMonthlyInvestment = obj => {
        const { errors, showMnthlyInvst, fields } = this.state;
        const errObj = { ...errors };
        const fieldsObj = { ...fields };
        const monthlyInvestmentValue =
            obj.minimumAutomaticInvestment && parseFloat(obj.minimumAutomaticInvestment).toFixed(2);

        if (showMnthlyInvst && (Object.keys(obj).indexOf('monthlyInvest') === -1 || obj.monthlyInvest === 0)) {
            errObj.mnthlyInvest = validFieldErrorTxt;
            fieldsObj.monthlyInvestValid = false;
        } else if (showMnthlyInvst && +obj.monthlyInvest < monthlyInvestmentValue) {
            errObj.mnthlyInvest = `${TransactionConstants.MONTHLY_INVESTMENT_VALIDATION_ERROR_MESSAGE}`;
            fieldsObj.monthlyInvestValid = false;
        } else if (showMnthlyInvst && +obj.monthlyInvest > 50000) {
            errObj.mnthlyInvest = `${TransactionConstants.MONTLY_INVESTMENT_MAX_VALIDATION_ERROR_MESSAGE}`;
            fieldsObj.monthlyInvestValid = false;
        } else {
            errObj.mnthlyInvest = '';
            fieldsObj.monthlyInvestValid = true;
        }
        return {
            errObj,
            fieldsObj,
        };
    };

    validateStartDate = obj => {
        const { errors, showMnthlyInvst, fields } = this.state;
        const errObj = { ...errors };
        const fieldsObj = { ...fields };

        if ((showMnthlyInvst && Object.keys(obj).indexOf('startDate') === -1) || obj.startDate === '') {
            errObj.startDate = startDateValidFieldErrorTxt;
            fieldsObj.startDateValid = false;
        } else if (showMnthlyInvst && !this.validateDate(obj.startDate)) {
            errObj.startDate = TransactionConstants.START_DATE_VALIDATION_ERROR_MESSAGE;
            fieldsObj.startDateValid = false;
        } else {
            errObj.startDate = '';
            fieldsObj.startDateValid = true;
        }
        return {
            errObj,
            fieldsObj,
        };
    };

    validateFormFields = (invokedFrom = '') => {
        const { analyzeFormFieldError } = this.props;
        const errObj = { subInvest: '', mnthlyInvest: '', startDate: '' };
        const fieldsObj = { initialInvestValid: false, monthlyInvestValid: false, startDateValid: false };

        try {
            const { selectedAccounts, fundOptionSelected } = this.state;
            const updatedArray = [...selectedAccounts];
            let returnedData = {};
            updatedArray.map((obj, index) => {
                if (obj.initialInvest_touched) {
                    returnedData = this.validateInitialInvestment(obj);
                    fieldsObj.initialInvestValid = returnedData.fieldsObj.initialInvestValid;
                    errObj.subInvest = returnedData.errObj.subInvest;
                    updatedArray[parseInt(index, 10)].initialInvest = obj.initialInvest;
                    updatedArray[parseInt(index, 10)].fundOptionSelected = fundOptionSelected;
                } else {
                    if (obj.initialInvest_touched || invokedFrom === 'next') {
                        errObj.subInvest = validFieldErrorTxt;
                    }
                    fieldsObj.initialInvestValid = false;
                    this.setState({ disabled: true });
                }
                if (obj.monthlyInvest_touched) {
                    returnedData = this.validateMonthlyInvestment(obj);
                    fieldsObj.monthlyInvestValid = returnedData.fieldsObj.monthlyInvestValid;
                    errObj.mnthlyInvest = returnedData.errObj.mnthlyInvest;
                    updatedArray[parseInt(index, 10)].monthlyInvest = obj.monthlyInvest;
                    updatedArray[parseInt(index, 10)].fundOptionSelected = fundOptionSelected;
                } else {
                    if (obj.monthlyInvest_touched || invokedFrom === 'next') {
                        errObj.mnthlyInvest = validFieldErrorTxt;
                    }
                    fieldsObj.monthlyInvestValid = false;
                }
                if (obj.startDate_touched) {
                    returnedData = this.validateStartDate(obj);
                    fieldsObj.startDateValid = returnedData.fieldsObj.startDateValid;
                    errObj.startDate = returnedData.errObj.startDate;
                    updatedArray[parseInt(index, 10)].startDate = obj.startDate;
                } else {
                    if (obj.startDate_touched || invokedFrom === 'next') {
                        errObj.startDate = startDateValidFieldErrorTxt;
                    }
                    fieldsObj.startDateValid = false;
                }

                if (
                    fieldsObj.initialInvestValid === false ||
                    fieldsObj.monthlyInvestValid === false ||
                    fieldsObj.startDateValid === false
                ) {
                    analyzeFormFieldError();
                }
                this.setState({
                    errors: errObj,
                    fields: fieldsObj,
                });

                return fieldsObj;
            });
        } catch (error) {
            this.setState({ displayNotification: true });
        }
        return fieldsObj;
    };

    getIsIRA = accountType => {
        return !!(
            accountType === TransactionConstants.TRADITIONAL_IRA ||
            accountType === TransactionConstants.ROTH_IRA ||
            accountType.trim() === TransactionConstants.SEP_IRA ||
            accountType.toLowerCase().includes(TransactionConstants.COVERDELL_EDUCATION_IRA) ||
            accountType.trim() === TransactionConstants.ROTH_CONVERSION_IRA ||
            accountType.trim() === TransactionConstants.ROLLOVER_IRA
        );
    };

    checkProportionateIsSelected = (arrayObj) =>
    {
        const filterArray = arrayObj.filter((item)=> item?.showProportionate == true && item?.checked == true)
        return filterArray?.length > 0 ? true : false;
    }


    validateInitialInvestField = (obj, e) => {
        const { onSwitchComponent, pageIndex, clearLogoutState } = this.props;
        if (obj.initialInvestValid) {
            clearLogoutState()

            onSwitchComponent(true, this.state, pageIndex)();
        }
    };

    validateForm = e => {
        const { onSwitchComponent, pageIndex,clearLogoutState } = this.props;
        const { fundOptionSelected, selectedAccounts } = this.state;

        if (fundOptionSelected === 'init' || fundOptionSelected === 'sub') {

            if(identifyExsitingAccountWithInitialInvestment(selectedAccounts,fundOptionSelected) == true)
            {
                const validateObj = this.validateExsitingAccountAndInitialInvestment(selectedAccounts[0]);
                if(validateObj.isValid)
                {
                    this.calculateTotalInvestment(selectedAccounts);
                    const obj = this.validateFormFields('next');
                    this.validateInitialInvestField(obj, e);
                }
                else
                {
                    this.setState({showConfirmModal:true})
                }
            }
            else
            {
                this.calculateTotalInvestment(selectedAccounts);
                const obj = this.validateFormFields('next');

                this.validateInitialInvestField(obj, e);
            }
        } else {

            this.calculateTotalInvestment(selectedAccounts);
            const obj1 = this.validateFormFields('next');
            if (obj1.initialInvestValid && obj1.monthlyInvestValid && obj1.startDateValid) {
                this.setState(
                    {
                        errors: {
                            subInvest: '',
                            mnthlyInvest: '',
                            startDate: '',
                        },
                    },
                    () => {
                        clearLogoutState()
                        onSwitchComponent(true, this.state, pageIndex)();
                    },
                );
            }
        }
    };

    validateDate = dateVal => {
        const date = new Date();
        // For two day check
        date.setHours(48);
        date.setMinutes(0);
        date.setSeconds(0);
        return Date.parse(dateVal) >= Date.parse(date);
    };

    handleMILFilter = list => {
        this.setState({ filteredMIL: list });
    };

    handleRiskFilter = list => {
        this.setState({ filteredRisk: list });
    };

    handleFTFilter = list => {
        this.setState({ filteredFundType: list });
    };

    handleErrorModalClick = val => {
        if (val === 'close' || val === 'hide') {
            this.setState({ showErrorModal: false, loading: false });
        }
    };

    formatInputValues = val => {
        //   return val ? val.replace(/[a-zA-Z,$.]/g, '') : 0;
        return val && parseFloat(val).toFixed(2);
    };

    formatChangeInNav = val => {
        // const changeInNav = val && val.toString().replace('-', '');
        return val && parseFloat(val).toFixed(2);
    };

    fundOptionHandler = (index, val) => {
        const { selectedAccounts } = this.state;
        const updatedArray = [...selectedAccounts];
        updatedArray[parseInt(index, 10)].initialInvest = '';
        updatedArray[parseInt(index, 10)].monthlyInvest = '';
        updatedArray[parseInt(index, 10)].startDate = '';
        updatedArray[parseInt(index, 10)].initialInvest_touched = false;
        updatedArray[parseInt(index, 10)].startDate_touched = false;
        updatedArray[parseInt(index, 10)].monthlyInvest_touched = false;
        updatedArray[parseInt(index, 10)].fundOptionSelected = val;
        this.calculateTotalInvestment(updatedArray);
        this.setState({
            errors: { subInvest: '', mnthlyInvest: '', startDate: '' },
            selectedAccounts: updatedArray,
            fundOptionSelected: val,
        });
        if (val === 'init' || val === 'sub') {
            this.setState({
                fundOptionSelected: val,
                showMnthlyInvst: false,
                totalMnlyInv: '',
                startDate: '',
            });
        } else {
            this.setState({ fundOptionSelected: val, showMnthlyInvst: true });
        }
    };

    handleLoadMore = e => {
        this.analyzeClick(e);
        const { setNextPageNumber } = this.props;
        setNextPageNumber();
    };

    handleCancel = () => {
        const { setCancelPurchase } = this.props;
        const { selectedAccounts } = this.state;
        setCancelPurchase(selectedAccounts[0]);
    };

    handleFundSummary = (fundNumber, event) => {
        event.stopPropagation();
        this.analyzeClick(event);
        const { getFundDetails } = this.props;
        if (fundNumber && fundNumber !== '') {
            const payload = {
                fundNumber,
            };
            getFundDetails(payload);
            this.setState({ fundSummaryClicked: true });
        }
    };

    updateState = prevProps => {
        const { accOpeningData, onSwitchComponent } = this.props;
        //   Fund List  Values end
        // Fund Details API Mapping Start
        if (
            accOpeningData[ActionTypes.GET_FUNDDETAILS] &&
            !accOpeningData.isLoading &&
            accOpeningData[ActionTypes.GET_FUNDDETAILS] !== prevProps.accOpeningData[ActionTypes.GET_FUNDDETAILS]
        ) {
            const tempData = accOpeningData[ActionTypes.GET_FUNDDETAILS];
            // Error handling
            if (tempData.errorCode && tempData.result) {
                const errorDescription = tempData.result;
                this.setState({
                    showAlert: true,
                    errorMessage: {
                        info: errorDescription,
                    },
                    isErrorBar: true,
                });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            } else if (!tempData.errorCode) {
                this.setState(
                    {
                        selectedFundForSummary: tempData,
                    },
                    () => {
                        const { fundSummaryClicked, selectedFundForSummary } = this.state;

                        if (Object.values(selectedFundForSummary).length > 0 && fundSummaryClicked) {
                            onSwitchComponent(true, this.state, 21)();
                        }
                    },
                );
            }
        }
    };

    analyzeClick = e => {
        const { analyzeClickEvent } = this.props;
        analyzeClickEvent({
            link: e.target.getAttribute('data-analytic-id') || e.currentTarget.getAttribute('data-analytic-id'),
        });
    };

    getAcountTypeIndex = (purchaseData,fundsAvailable,currentPageNumber) =>
    {
        return ((purchaseData.showAllFunds
            ? fundsAvailable.length
            : currentPageNumber *
              TransactionConstants.NUMBER_OF_FUNDS_PER_PAGE))
    }

    bindComponent() {
        const { fundsAvailable, filterButtonsReq, isLoading, isError } = this.state;
        const { purchaseData } = this.props;
        const { currentPageNumber } = purchaseData;
        const { stateData } = { ...this.state };

        return (
            <>
                <Accordian
                    ref={this.invSelectionRef}
                    titleChild={
                        <AccordianTitle
                            id={TransactionConstants.ACCORDIAN_HEADING.replace(/ /g, '_') + currentPageNumber}
                            accType={TransactionConstants.SELECT_MUTUAL_FUND_TEXT}
                            className={`title_${TransactionConstants.ACCORDIAN_HEADING.replace(
                                / /g,
                                '_',
                            )}${currentPageNumber}`}
                        />
                    }
                    titleSeperator
                    btnProps={stateData}
                    filterBtnReq={filterButtonsReq}
                    Id={TransactionConstants.ACCORDIAN_HEADING.replace(/ /g, '_')}
                    labelledBy={TransactionConstants.ACCORDIAN_HEADING.replace(/ /g, '_') + currentPageNumber}
                    transactionSubTitle={TransactionConstants.SELECT_FUND_FROM_LIST_BELOW}
                >
                    <div>
                        {!isError && !isLoading && fundsAvailable && fundsAvailable.length === 0 && (
                            <>
                                <EmptyAccountsP>{TransactionConstants.NO_ACCOUNTS_MESSAGE}</EmptyAccountsP>
                            </>
                        )}
                        {fundsAvailable ? (
                            <>
                                {' '}
                                {fundsAvailable.map(
                                    (item, acctypeindex) =>
                                        acctypeindex <
                                            (this.getAcountTypeIndex(purchaseData,fundsAvailable,currentPageNumber)) && (
                                                    <>
                                                    {item?.showProportionate ?
                                                       <ProportionateCardComponent
                                                       key={item.fundNumber}
                                                        
                                                        minimumTxt={TransactionConstants.MINIMUM_TEXT}
                                                        fundAutoInvstTxt={TransactionConstants.FUND_MINMAX_TEXT}
                                                        fundNavTxt={TransactionConstants.FUND_NAV_TEXT}
                                                        fundLastNavTxt={TransactionConstants.FUND_LAST_NAV_TEXT}
                                                        fundMinMaxTxt={TransactionConstants.FUND_WEEK_MIN_MAX_TEXT}
                                                        fundRiskTxt={TransactionConstants.FUND_RISK}
                                                        accountName={'Proportionate'}
                                                        fundNumber={item.fundNumber}
                                                        minInvst={this.formatInputValues(item.initialInvestment)}
                                                        maxInvst={this.formatInputValues(
                                                            item.minimumInitialMonthlyInvestment,
                                                        )}
                                                        monthlyInvst={this.formatInputValues(
                                                            item.minimumAutomaticInvestment,
                                                        )}
                                                        nav={this.formatChangeInNav(item.changeInNav)}
                                                        changeInNavPercentage={
                                                            item.changeInNavPercentage
                                                                ? this.formatChangeInNav(item.changeInNavPercentage)
                                                                : '0.00'
                                                        }
                                                        lastNav={this.formatInputValues(item.nav)}
                                                        weekValuesMin={
                                                            item.min52W !== '-'
                                                                ? this.formatInputValues(item.min52W)
                                                                : item.min52W
                                                        }
                                                        weekValuesMax={
                                                            item.max52W !== '-'
                                                                ? this.formatInputValues(item.max52W)
                                                                : item.max52W
                                                        }
                                                        riskVal={item.risk}
                                                        switchId={item.fundNumber + acctypeindex}
                                                        onClick={Object.assign(e =>
                                                            this.onFundListCheckboxClick(
                                                            acctypeindex,
                                                            item.fundNumber,
                                                            item.fundAccountNumber,
                                                            false,
                                                            e
                                                        ))}
                                                        checked={!!item.checked}
                                                        selected={!!item.checked}
                                                        headerClick
                                                        data-test="invest-account-card"
                                                        //   The item.fundNumber has to be changed to fundAccountNumber
                                                        existingFund={getValidValueOf(item.fundAccountNumber,TransactionConstants.EXISTING_FUND,'')}
                                                        
                                                        fundName={item.fundName}
                                                       
                                                       />
                                                    :
                                                    <ErrorBoundary key={item.fundNumber + item.fundName}>
                                                    
                                                    <TransactionInvestAccountCardComponent
                                                        key={item.fundNumber}
                                                        id={item.fundNumber + item.fundName.replace(/ /g, '_')}
                                                        minimumTxt={TransactionConstants.MINIMUM_TEXT}
                                                        fundAutoInvstTxt={TransactionConstants.FUND_MINMAX_TEXT}
                                                        fundNavTxt={TransactionConstants.FUND_NAV_TEXT}
                                                        fundLastNavTxt={TransactionConstants.FUND_LAST_NAV_TEXT}
                                                        fundMinMaxTxt={TransactionConstants.FUND_WEEK_MIN_MAX_TEXT}
                                                        fundRiskTxt={TransactionConstants.FUND_RISK}
                                                        accountName={item.fundName}
                                                        fundNumber={item.fundNumber}
                                                        minInvst={this.formatInputValues(item.initialInvestment)}
                                                        maxInvst={this.formatInputValues(
                                                            item.minimumInitialMonthlyInvestment,
                                                        )}
                                                        monthlyInvst={this.formatInputValues(
                                                            item.minimumAutomaticInvestment,
                                                        )}
                                                        nav={this.formatChangeInNav(item.changeInNav)}
                                                        changeInNavPercentage={
                                                            item.changeInNavPercentage
                                                                ? this.formatChangeInNav(item.changeInNavPercentage)
                                                                : '0.00'
                                                        }
                                                        lastNav={this.formatInputValues(item.nav)}
                                                        weekValuesMin={
                                                            item.min52W !== '-'
                                                                ? this.formatInputValues(item.min52W)
                                                                : item.min52W
                                                        }
                                                        weekValuesMax={
                                                            item.max52W !== '-'
                                                                ? this.formatInputValues(item.max52W)
                                                                : item.max52W
                                                        }
                                                        riskVal={item.risk}
                                                        switchId={item.fundNumber + acctypeindex}
                                                        onClick={Object.assign(e =>
                                                            this.onFundListCheckboxClick(
                                                            acctypeindex,
                                                            item.fundNumber,
                                                            item.fundAccountNumber,
                                                            false,
                                                            e
                                                        ))}
                                                        checked={!!item.checked}
                                                        selected={!!item.checked}
                                                        headerClick
                                                        data-test="invest-account-card"
                                                        //   The item.fundNumber has to be changed to fundAccountNumber
                                                        existingFund={getValidValueOf(item.fundAccountNumber,TransactionConstants.EXISTING_FUND,'')}
                                                        isRewardShares={item.fundNumber === '33' && !item.fundAccountNumber}
                                                        fundName={item.fundName}
                                                        handleFundSummary={this.handleFundSummary}
                                                    />
                                                
                                                    </ErrorBoundary>
                                                    }
                                                    </>
                                           
                                            
                                        ),
                                )}
                                {Math.ceil(fundsAvailable.length / TransactionConstants.NUMBER_OF_FUNDS_PER_PAGE) >
                                    currentPageNumber &&
                                    !purchaseData.showAllFunds && (
                                        <LoadMoreDiv
                                            onClick={this.handleLoadMore}
                                            data-analytic-id="Transactions_Buy_InvestmentSelection_ShowMore"
                                        >
                                            <LoadMoreSpan>{TransactionConstants.TRANSACTION_LOAD_MORE}</LoadMoreSpan>
                                            <img src={iconDown} alt="" />
                                        </LoadMoreDiv>
                                    )}
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </Accordian>
            </>
        );
    }

    bindFundInvestComponent() {
        const {
            selectedAccounts,
            totalSubAmt,
            totalMnlyInv,
            fundsAvailable,
            errors,
            masterLookupData,
            showMnthlyInvst,
            fundOptionSelected
        } = this.state;

        const { tradingWindowData } = this.props;
        return (
            <ErrorBoundary>
                <FundInvest
                    selectedFund={selectedAccounts}
                    removeFund={this.removeFundHandler}
                    totalSubAmt={totalSubAmt}
                    totalMnlyInv={totalMnlyInv}
                    fundsAvailable={fundsAvailable}
                    changeSubInv={this.changeSubInvHandler}
                    errors={errors}
                    fundOptionHandler={this.fundOptionHandler}
                    masterLookupData={masterLookupData}
                    showMnthlyInvst={showMnthlyInvst}
                    fundOptionSelected={fundOptionSelected}
                    validateFormFields={this.validateFormFields}
                    formatInputValues={this.formatInputValues}
                    tradingWindowData={tradingWindowData}
                    data-test="fund-invest"
                />
            </ErrorBoundary>
        );
    }   

    validateExsitingAccountAndInitialInvestment = (obj) =>
    {
        const { errors, fields, fundOptionSelected } = this.state;
        const errObj = { ...errors };
        const fieldsObj = { ...fields };

        const initialInvestValBasedOnFundOptions = obj.fundAccountNumber
            ? this.formatInputValues(obj.minimumAutomaticInvestment)
            : this.formatInputValues(
                    fundOptionSelected === 'init' ? obj.initialInvestment : obj.minimumInitialMonthlyInvestment,
                );
        let isValid = true;

        if (Object.keys(obj).indexOf('initialInvest') === -1 || obj.initialInvest === 0) {
            errObj.subInvest = validFieldErrorTxt;
            fieldsObj.initialInvestValid = false;
        } else if (+parseFloat(removeComma(obj.initialInvest)) < parseFloat(removeComma(initialInvestValBasedOnFundOptions))) {
            isValid= false;
        } else {
            errObj.subInvest = '';
            fieldsObj.initialInvestValid = true;
        }

        return {
            errObj,
            fieldsObj,
            isValid
        }
    }

    handleCancelPopup = () =>{
        this.setState({
            showConfirmModal:false
        })
    }

    handleProceed = () =>{
        const e ={};
        this.setState({
            showConfirmModal:false
        },()=>{
            this.calculateTotalInvestment(this.state.selectedAccounts);
            const obj = this.validateFormFields('next');
            this.validateInitialInvestField(obj, e);
        });
    }
    

    render() {
        const { showInvestFund,selectedAccounts, masterLookupData, isLoading, accountNumber, accountName,showWarningDialog,isFee,touchedFundName,touchedFundPercentValue,showConfirmModal } = this.state;
        const { onSwitchComponent, handleError, fundAccountData } = this.props;
        if (masterLookupData.isError || fundAccountData.isError) {
            handleError(true);
        }

        return (
            <div id="#currentPageName" pagename="Investment Selection" data-test="investmentSel-component">
                {isLoading && <WSpinner loading={isLoading} />} 

                {showConfirmModal && (
                    <>
                    <ConformationModal
                        modalTitle={TransactionConstants.MODAL_HEADING}
                        modalBodyText={TransactionConstants.MODAL_BODY_TEXT}
                        primaryButtonText="Proceed"
                        onPrimaryClick={this.handleProceed}
                        secondaryButtonText={"Cancel"}
                        onSecondaryClick={this.handleCancelPopup}
                    />
                    </>
                    
                )}

                <div className="container">
                    <div style={AccountCard} data-test="account-info-card">
                        <AccountInfoCard accountName={accountName} accountNumber={accountNumber} />
                    </div>
                    {this.bindComponent()}
                    {(showInvestFund && selectedAccounts && selectedAccounts.length>0) ? this.bindFundInvestComponent() :   ''}                    
                    {showWarningDialog&&
                        <FeeGatesWarningModal
                            isFee={isFee}
                            isLiquidate={false}
                            show={showWarningDialog}
                            fundName={touchedFundName}
                            feePercentValue={touchedFundPercentValue}
                            onPrimaryClick= {this.onYesFeeClick} 
                            onSecondaryClick={this.onNoFeeClick}
                        />
                    }                   
                </div>
                
                <MainDiv>
                    <BtnWrap>
                        
                        <div>
                            <WButton
                                variant="back"
                                buttontext={TransactionConstants.BACK_TEXT}
                                onClick={onSwitchComponent(false)}
                                data-test="back-button"
                                aria-label="Back to Account Selection"
                                id="desktopBackButton"
                                dataAnalyticId="Transactions_Buy_InvestmentSelection_BackButton"
                            />
                        </div>
                        <div>
                            <WButton
                                variant="cancel"
                                buttontext="Cancel"
                                id="desktopFundSelectionCancelButton"
                                buttonstyle={assignObj(cancelButtonStyles)}
                                onClick={this.handleCancel}
                                dataAnalyticId="Transactions_Buy_InvestmentSelection_CancelButton"
                            />
                        </div>
                        <div>
                            <WButton
                                disabled={false}
                                buttontext={TransactionConstants.NEXT_TEXT}
                                onClick={this.validateForm}
                                data-test="next-button"
                                id="desktopNextButton"
                                dataAnalyticId="Transactions_Buy_InvestmentSelection_NextButton"
                            />
                        </div>
                    </BtnWrap>
                </MainDiv>
            </div>
        );
    }
}

export const mapStateToProps = state => {
    return {
        fundAccountData: state.fundAccountData,
        purchaseData: state.purchaseData,
        accOpeningData: state.accOpeningReducerData,
        transactionHolderData: state.transactionHolderData,
    };
};

InvestmentSelection.propTypes = {
    onSwitchComponent: PropTypes.func,
    savedState: PropTypes.instanceOf(Object),
    fundAccountData: PropTypes.instanceOf(Object),
    pageIndex: PropTypes.number,
    editSection: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    masterLookupStateData: PropTypes.instanceOf(Object),
    getCompositeData: PropTypes.func,
    handleError: PropTypes.func,
    getFunds: PropTypes.func,
    purchaseData: PropTypes.instanceOf(Object),
    setTradingWarning: PropTypes.func,
    setNextPageNumber: PropTypes.func,
    location: PropTypes.instanceOf(Object),
    setCancelPurchase: PropTypes.func,
    getFundDetails: PropTypes.func,
    accOpeningData: PropTypes.instanceOf(Object),
    setNextPageNumberPositions: PropTypes.func,
    tradingWindowData: PropTypes.instanceOf(Object),
    getTransHolderList: PropTypes.func,
    logoutRouteData: PropTypes.instanceOf(Object),
    clearLogoutState: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
    analyzeFormFieldError: PropTypes.func,
};

InvestmentSelection.defaultProps = {
    savedState: null,
    handleError: () => {},
    onSwitchComponent: () => {},
    pageIndex: 1,
    editSection: null,
    masterLookupStateData: {},
    fundAccountData: {},
    getCompositeData: () => {},
    getFunds: () => {},
    purchaseData: {},
    setTradingWarning: () => {},
    setNextPageNumber: () => {},
    location: {},
    setCancelPurchase: () => {},
    getFundDetails: () => {},
    accOpeningData: {},
    setNextPageNumberPositions: () => {},
    tradingWindowData: {},
    getTransHolderList: () => {},
    logoutRouteData: () => {},
    clearLogoutState: () => {},
    analyzeClickEvent: () => {},
    analyzeFormFieldError: () => {},
};

export default connect(mapStateToProps)(InvestmentSelection);
